export const SNACK_SUCCESS = 'success'
export const SNACK_ERROR = 'error'
export const SNACK_WARNING = 'warning'
export const USUARIO_SIN_PERMISOS = 'El Usuario no posee los permisos suficientes.'
export const ERROR_SERVICIO_LOGIN = 'Ha ocurrido un error al autenticarse'
export const CREDENCIALES_INCORRECTAS = 'Usuario y Contraseña incorrectas'
export const MENSAJE_INGRESO_EXITOSO = "Ingreso exitoso"
export const ERROR_ENVIAR_MAIL = "Hubo un error al enviar el mail, por favor intente nuevamente."
export const EXITO_ENVIAR_MAIL = "Mail enviado con éxito"
export const PORTAL_CLIENTE_APP = "CLIENTE"
export const MESSAGE_SUPERACION_CANTIDAD_INTENTOS = "No pudimos validar tu usuario, superaste la cantidad de intentos. Ponte en contacto con soporte haciendo click "
export const MESSAGE_NO_SE_PUDO_VALIDAR_FOTO = "No pudimos validar tu foto, intente nuevamente. Intento "
export const MESSAGE_CANTIDAD_INTENTOS = " de 3."
export const EMPTY_STRING = ""
export const SPACE_STRING = " "
export const VALIDATE_CAMPO_COMPLETE_MESSAGE="Campo obligatorio"
export const MESSAGE_VALIDACION_NEW_PASSWORD = 'Las contraseñas deben coincidir'
export const CONTRASEÑA_ANTERIOR = "Contraseña Anterior *"
export const REINGRESE_CONTRASEÑA = "Reingrese Contraseña *"
export const NUEVA_CONTRASEÑA = "Nueva Contraseña *"
export const CAMBIAR_CONTRASEÑA = "Cambiar Contraseña"
export const MESSAGE_SUCCESS_CAMBIO_CONTRASENIA = "Se cambió la contraseña exitosamente!"
export const MESSAGE_ERROR_CAMBIO_CONTRASENIA = "Hubo un error al cambiar la contraseña, intente nuevamente"
export const CAMBIAR_CONTRASENIA_LABEL = "Cambiar Contraseña"
export const INICIAR_SESION_LABEL = "Iniciar sesión"
export const INGRESAR_AL_SISTEMA_LABEL= 'Ingresar al sistema'
export const CONTRASENIA_OBLIGATORIA_MESSAGE = 'Contraseña Obligatoria'
export const CONTRASENIA_LABEL = 'Contraseña *'
export const USUARIO_LABEL = 'Usuario *'
export const INICIE_SESION_CREDENCIALES_MESSAGE="Inicie sesión con sus credenciales"
export const BIENVENIDO_LABEL = "Bienvenido!"
export const USUARIO_OBLIGATORIO_LABEL = 'Usuario Obligatorio'
export const SAS_TITLE = "Sistema de Administración de Siniestros"
export const SAS_LABEL = "SAS"
export const RECONOCIMIENTO_FACIAL_LABEL = "Reconocimiento Facial"
export const ESCANEANDO_TU_ROSTRO_LABEL = "Escaneando tu rostro..."
export const MANTENTE_FRENTE_CAMARA_MESSAGE = "Mantente de frente a la cámara mientras se realiza la validación."
export const TIP_FACE_RECOGNITION_MESSAGE = "Tip: Busca una buena iluminación."
export const INGRESE_DATOS_REQUERIDOS_CAMBIAR_CONTRASEÑA = "Ingrese datos requeridos para cambiar contraseña"
export const VALIDAR_CONTRASENIA_ERROR = "Hubo un error al validar la contraseña"
export const CONTRASENIA_INCORRECTA = "Hubo un error al validar la contraseña: Contraseña Incorrecta ?"