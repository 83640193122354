//BASE
export const URL_API = process.env.REACT_APP_URL_SERVICIOS;
export const PORT_LOGIN = process.env.REACT_APP_PORT_LOGIN
export const URL_UI = process.env.REACT_APP_URL_UI;
export const URL_PORT_HOME = process.env.REACT_APP_PORT_HOME
export const URL_HOME = process.env.REACT_APP_URL_HOME
export const URL_PORT_PORTAL_CLIENTES = process.env.REACT_APP_PORT_PORTAL_CLIENTES
export const CONTEXT_LOGIN = '/grv/login';
export const CONTEXT_NOTIFICACIONES = '/grv/notificaciones';

//LOGIN
export const URL_FRONT_UI_PERMITIONS = URL_API;
export const FECTH_URL_LOGIN = `${URL_API}${PORT_LOGIN}${CONTEXT_LOGIN}/login/token`
export const FETCH_URL_FACE_RECOGNITION_VALIDATE = `${URL_API}${PORT_LOGIN}${CONTEXT_LOGIN}/login/verify-face-recognition-required`
export const FETCH_URL_CHANGE_PASSWORD = `${URL_API}${PORT_LOGIN}${CONTEXT_LOGIN}/change-password`

//REDIRECCIONES
export const URL_FRONT_UI = `${URL_HOME}${URL_PORT_HOME}/home`;
export const URL_FRONT_PORTAL_CLIENTE = `${URL_HOME}${URL_PORT_PORTAL_CLIENTES}/portal/home`

//Notificaciones
export const URL_FETCH_MAIL_SOPORTE = `${URL_API}${PORT_LOGIN}${CONTEXT_NOTIFICACIONES}/email/face-recognition-soporte`