import { EMPTY_STRING, PORTAL_CLIENTE_APP, SPACE_STRING } from "./const";
import { URL_FRONT_PORTAL_CLIENTE, URL_FRONT_UI } from "./url";

class Utils {
    static usuarioConPermisos(roles) {
        if (roles) {
            let rol = roles.filter(it => it === 'Supervisor' || it === 'Operador')
            if (rol && rol[0]) {
                return true;
            }
        }
        return false;
    }
    //Valida el area del usuario que se loguea y lo redirige a la app de esa área:
    static verificarAreaUsuarioLogueadoYRedireccion = (datosUsuario) => {
        //valido que tenga un solo valor en el atributo APP de keycloak
        if( datosUsuario.apps.length == 1 ) {
            switch (datosUsuario.apps[0]) {
                //PORTAL_CLIENTES
                case PORTAL_CLIENTE_APP:
                    window.location.href = URL_FRONT_PORTAL_CLIENTE
                    break;
                //CEM
                default:
                    window.location.href = URL_FRONT_UI
            }
        } else {
            //En caso de tener mas de un tributo se redirige al front que agrupa disitintas areas
            //(CEM - Contrataciones - Auditoria Medica)
            window.location.href = URL_FRONT_UI
        }
    }
    //Valida de no colocar Caracteres Especiales ni espacios Vacios
    static validarCaracteresEspeciales= (text) => {
        const regex=/^[a-zA-Z0-9]*$/
        if(regex.test(text)){
            return true
        } 
        return false
        
    }

    //Valida si las nuevas contraseñas y el usuario estan en null, " " o ""
    static validateNuevasContraseniasIncorrectas= (user,newPassword,newPassword2,oldPassword) => {
        if(newPassword === null || newPassword === EMPTY_STRING || newPassword2 === null || newPassword2 === EMPTY_STRING || user === undefined || user === EMPTY_STRING || oldPassword === EMPTY_STRING || oldPassword === undefined ){
            return true
        }
        return false
    }
}

export default Utils;
