import { ThemeProvider } from 'styled-components';
import './App.css';
import Login from './components/login/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { useEffect } from 'react';
import { URL_API } from './components/utils/url';

const theme = createTheme({
  typography: {
    "textAlign": 'left',
  },
  palette: {
    primary: {
      main: '#1473e6',
      secondary: '#4b4b4b',
    },
    secondary: {
      main: '#747474',
    },
  }

})

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    let url = window.location.href.toString()
    let urlDividida = url.split("/")
    // console.log(urlDividida)
    if (urlDividida[3] !== "login" && localStorage.getItem("datos_usuario") === null) {
      navigate("/login")
      window.location.href = URL_API + "/login"
    }
  }, [])

  useEffect(() => {
    let url = window.location.href.toString()
    let urlDividida = url.split("/")
    if (urlDividida[3] !== "login" && localStorage.getItem("datos_usuario") === null) {
      window.location.href = URL_API + "/login"
    }
  }, [window.location])

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            exact
            path="/login"
            element={<Login />}
          />
          <Route
            exact
            path=""
            element={<Login />}
          />
        </Routes>
      </ThemeProvider>
    </CookiesProvider>

  );
}

export default App;
