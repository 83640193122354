//REACT
import React, { useState, useEffect, useRef } from 'react';
//MATERIAL
import { Alert, Container, Grid, IconButton, InputAdornment, Snackbar } from '@mui/material';
import {VisibilityOff,Visibility} from '@mui/icons-material';
import { Box } from '@mui/system';
//COMPONENTS
import CustomLoading from '../commons/Loading/CustomLoading';
import CustomText from '../commons/TextField/CustomText';
import CustomButton from '../commons/Button/CustomButton';
import CustomTypography from '../commons/Typography/CustomTypography';
//LOGOS - IMAGENES
import PNGLogin from '../../commons/assets/login.png'
import isoLogo from '../../commons/assets/isologoColoniaSuiza.svg'
import { ReactComponent as Lampara } from "../login/lampara.svg"
//UTILS
import Utils from '../utils/utils';
import { FECTH_URL_LOGIN, FETCH_URL_CHANGE_PASSWORD, FETCH_URL_FACE_RECOGNITION_VALIDATE, URL_FETCH_MAIL_SOPORTE } from '../utils/url';
import { BIENVENIDO_LABEL, CAMBIAR_CONTRASENIA_LABEL, CAMBIAR_CONTRASEÑA, CONTRASENIA_INCORRECTA, CONTRASENIA_LABEL, CONTRASENIA_OBLIGATORIA_MESSAGE, CONTRASEÑA_ANTERIOR, EMPTY_STRING, ERROR_ENVIAR_MAIL, ERROR_SERVICIO_LOGIN, ESCANEANDO_TU_ROSTRO_LABEL, EXITO_ENVIAR_MAIL, INGRESAR_AL_SISTEMA_LABEL, INGRESE_DATOS_REQUERIDOS_CAMBIAR_CONTRASEÑA, INICIAR_SESION_LABEL, INICIE_SESION_CREDENCIALES_MESSAGE, MANTENTE_FRENTE_CAMARA_MESSAGE, MENSAJE_INGRESO_EXITOSO, MESSAGE_CANTIDAD_INTENTOS, MESSAGE_ERROR_CAMBIO_CONTRASENIA, MESSAGE_NO_SE_PUDO_VALIDAR_FOTO, MESSAGE_SUCCESS_CAMBIO_CONTRASENIA, MESSAGE_SUPERACION_CANTIDAD_INTENTOS, MESSAGE_VALIDACION_NEW_PASSWORD, NUEVA_CONTRASEÑA, RECONOCIMIENTO_FACIAL_LABEL, REINGRESE_CONTRASEÑA, SAS_LABEL, SAS_TITLE, SNACK_ERROR, SNACK_SUCCESS, TIP_FACE_RECOGNITION_MESSAGE, USUARIO_LABEL, USUARIO_OBLIGATORIO_LABEL, USUARIO_SIN_PERMISOS, VALIDAR_CONTRASENIA_ERROR, VALIDATE_CAMPO_COMPLETE_MESSAGE } from '../utils/const';

const classes = {
    root: {
        paddingRight: '0px',
        '@media (min-width: 600px)': {
            '.css-1cqj68g-MuiContainer-root': {
                paddingLeft: "0px",
                paddingRight: "0px"
            },
            ".css-s0th6m": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        }

    },
    contenedor: {
        paddingLeft: '0px',
        // paddingRight: '1px'

    },
    contenedorImagen: {
        display: 'flex',
        contentVisibility: 'visible',
        width: '54em',
        height: '39em',
        '& h4': {
            fontWeight: 600
        },
        '& h4, & h6': {
            color: 'fff'
        }

    },
    imagen: {
        backgroundImage: `linear-gradient(
             rgb(-25%,30%,104%, 0.5),
             rgba(-25, 30, 104, 0.5)
           ),url(${PNGLogin})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        display: 'flex',
        height: '100vh',

    },
    colorImagen: {
        width: '66%',
        height: '100%',
        objectFit: 'cover',
        zIndex: '+1',
        position: 'absolute',
        backgroundColor: '#124680',
        opacity: '0.75',
        margin: '0px',
        padding: '0px'
    },
    fondo: {
        display: 'flex',
        alignItems: 'center',

    },
    tituloImagen: {
        width: '50%',
        height: '-webkit-fill-available',
        margin: '2px 0 0',
        zIndex: '+2',
        position: 'absolute',
        textAlign: 'left',
        paddingLeft: '25px',
        justifyContent: "center"
    },
    ".MuiGrid-justify-xs-center": {
        justifyContent: "center"
    },
    typograhy2: {
        width: '232px',
        height: '21px',
        margin: '11px 59px 33.5px 0',
        fontSize: '17px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1473e6',
    },
    titulo: {
        width: '220px'
    },
    subtitulo: {
        marginLeft: '10px'
    }
};

const Login = (props) => {

    //HOOKS
    const videoRef = useRef(null)
    
    //STATES
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingEffect, setLoadingEffect] = useState(false)
    const [openRecognition, setOpenRecognition] = useState(false)
    const [valid, setValid] = useState(true)
    const [openSnackBar, setOpenSnackBar] = useState({
        open: false,
        title: '',
        severity: '',
        button: false
    });
    const [intentos, setIntentos] = useState(0)
    const [disabledButton, setDisabledButton] = useState(false)
    const [showPassword,setShowPassword] = useState(false)
    
    //Cambio contraseña states
    
    //Para cambiar de pantalla a los campos requeridos para cambiar contraseña
    const [changePassword,setChangePassword] = useState(false) 

    //States necesarios para validar si puede cambiar contraseña o no
    const [oldPassword,setOldPassword] = useState('')
    const [showContraseniaAnterior,setShowContraseniaAnterior] = useState(false)
    const [validateOldPasswordComplete,setValidateOldPasswordComplete] = useState(false)

    //States para cambiar contraseña
    const [newPassword,setNewPassword] = useState("")
    const [showNewPassword,setShowNewPassword] = useState(false)
    const [newPassword2,setNewPassword2] = useState("")
    const [showVerifyNewPassword,setShowVerifyNewPassword] = useState(false)
    const [verifyNewPasswordComplete,setVerifyNewPasswordComplete] = useState(true)
    const [verifyNewPassword,setVerifyNewPassword]= useState(true)
    const [disableButtonNewPassword,setDisabledButtonNewPassword] = useState(false)

    //Limpia states segun si cambia la pantalla de inicio sesión con cambio contraseña
    useEffect(()=>{
        setUser('')
        setPwd('')
        if(changePassword){   
            setOldPassword('')
            setNewPassword("")
            setNewPassword2('')
        }
    },[changePassword])
    
    
    //Modulo Reconocimiento Facial
    
    //Validacion para boton de inicio de sesión
    useEffect(() => { setDisabledButton(Boolean(!user || !pwd)) }, [user, pwd])
    
    //Llamada para mail de soporte en caso que no pueda entrar por reconocimiento facial
    const handleEnviarMailSoporte = () => {
        if (user) {
            let request = {
                usuario: user
            }
            setLoadingEffect(true)
            fetch(URL_FETCH_MAIL_SOPORTE, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => res.json()
                .then(data => {
                    if (data.status === 200) {
                        setLoadingEffect(false)
                        setOpenSnackBar({
                            open: true,
                            severity: SNACK_SUCCESS,
                            title: EXITO_ENVIAR_MAIL,
                            button: false
                        })
                    } else {
                        setLoadingEffect(false)
                        setOpenSnackBar({
                            open: true,
                            severity: SNACK_ERROR,
                            title: ERROR_ENVIAR_MAIL,
                            button: false
                        })
                    }
                })
            ).catch(err => {
                console.log(err)
            })
        }
    }

    //Valida la cantidad de intentos y muestra mensaje por pantalla
    useEffect(() => {
        if (intentos > 0) {
            if (intentos >= 3) {
                setOpenSnackBar({
                    open: true,
                    severity: SNACK_ERROR,
                    title: MESSAGE_SUPERACION_CANTIDAD_INTENTOS,
                    button: true
                })
            } else {
                setOpenSnackBar({
                    open: true,
                    severity: SNACK_ERROR,
                    title: MESSAGE_NO_SE_PUDO_VALIDAR_FOTO + intentos + MESSAGE_CANTIDAD_INTENTOS,
                    button: false
                })
            }
        }
    }, [intentos])

    //Llamada para verificar si el usuario necesita reconocimiento facial o no
    const verifyFaceRecognitionRequired = () => {
        if (user && user !== '' && pwd && pwd !== '') {
            let req = {
                user: user.trim(),
                password: pwd.trim()
            }
            setLoadingEffect(true)
            fetch(FETCH_URL_FACE_RECOGNITION_VALIDATE, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(req)
            }).then(res =>
                res.json()
                    .then(data => {
                        if (data && data.status && data.status === 500) {
                            setLoadingEffect(false)
                            setOpenSnackBar({
                                open: true,
                                severity: SNACK_ERROR,
                                title: USUARIO_SIN_PERMISOS,
                                button: false
                            })
                        }
                        if (data && data.user && data.user.faceRecognitionRequired === false) {
                            setDisabledButton(true)
                            setTimeout(function () {
                                setOpenSnackBar({
                                    open: true,
                                    severity: SNACK_SUCCESS,
                                    title: MENSAJE_INGRESO_EXITOSO,
                                    button: false
                                })
                                setLoadingEffect(false)
                                setOpenRecognition(false);

                                let datosUsuario = {
                                    token: data.access_token,
                                    user: data.user.username,
                                    id: data.user.id,
                                    firstName: data.user.firstName,
                                    lastName: data.user.lastName,
                                    roles: data.user.roles,
                                    apps: data.user.apps,
                                    area: data.user.area,
                                    idGrupo:data && data.user && data.user.idGrupo 
                                }
                                //Redireccion a fronts
                                document.cookie = "datos_usuario=" + JSON.stringify(datosUsuario)
                                document.cookie = "user_image=" + JSON.stringify(data.user_image)
                                Utils.verificarAreaUsuarioLogueadoYRedireccion(datosUsuario)
                            }, 5000);
                        } else {
                            if (data && data.status && data.status !== 200) {
                                setLoadingEffect(false)
                                setOpenSnackBar({
                                    open: true,
                                    severity: SNACK_ERROR,
                                    title: "Hubo un error, intente nuevamente.",
                                    button: false
                                })
                            } else {
                                setTimeout(function () {
                                    if (user && user !== '' && pwd && pwd !== '') {
                                        pythonCall();
                                        setOpenRecognition(true);
                                    } else {
                                        setValid(false)
                                    }
                                }, 5000)
                            }
                        }
                    }).catch(err => {
                        setIntentos(intentos + 1)
                        setLoading(false)
                        setOpenSnackBar({
                            open: true,
                            severity: SNACK_ERROR,
                            title: ERROR_SERVICIO_LOGIN,
                            button: false
                        })
                    })
            ).catch(() => {
                setIntentos(intentos + 1)
                setLoading(false)
                setOpenSnackBar({
                    open: true,
                    severity: SNACK_ERROR,
                    title: ERROR_SERVICIO_LOGIN,
                    button: false
                })
            })
        }
    }

    //Si necesita reconocimiento facial prender camara una vez ingresado las credenciales.
    const getVideo = () => {
        navigator
            .mediaDevices
            .getUserMedia({
                video: true
            })
            .then(stream => {
                let video = videoRef.current
                if ("srcObject" in video) {
                    video.srcObject = stream
                    video.play()
                }
            })
            .catch(err => console.log(err))
    }

    //Sacar foto a partir de la camara escendida
    const getScreenshotFromCameraBlob = () => {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        video.setAttribute("autoplay", true);
        return new Promise((resolve, reject) => navigator.mediaDevices
            .getUserMedia({
                video: true
            })
            .then((stream) => {
                if ("srcObject" in video) {
                    video.srcObject = stream;
                } else {
                    video.src = window.URL.createObjectURL(stream);
                }
                video.addEventListener("loadeddata", () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    var ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob(resolve, 'image/png');
                })
            })
            .catch(reject)
        )
    }

    //Metodo que llama al servicio de reconocimiento facial, si se valida que el usuario que ingresa es realmente un usuario del sistema
    //se guarda los datos de sesion en document.cookie
    const pythonCall = () => {
        setLoadingEffect(false)
        getScreenshotFromCameraBlob()
            .then((blob) => {
                const formData = new FormData();
                formData.append("file", blob);
                formData.append("user", user.trim());
                formData.append("password", pwd.trim());
                let url = FECTH_URL_LOGIN;
                fetch(url, {
                    method: 'POST',
                    body: formData
                })
                    .then((response) => {
                        response.json()
                            .then(data => {
                                if (data && data.access_token && data.user) {
                                    setDisabledButton(true)
                                    setOpenSnackBar({
                                        open: true,
                                        severity: SNACK_SUCCESS,
                                        title: MENSAJE_INGRESO_EXITOSO,
                                        button: false
                                    })
                                    if (Utils.usuarioConPermisos(data.user.roles)) {
                                        setTimeout(function () {
                                            setOpenRecognition(false);
                                            let datosUsuario = {
                                                token: data.access_token,
                                                user: data.user.username,
                                                id: data.user.id,
                                                firstName: data.user.firstName,
                                                lastName: data.user.lastName,
                                                roles: data.user.roles,
                                                apps: data.user.apps,
                                                area: data.user.area,
                                                idGrupo:data && data.user && data.user.idGrupo 
                                            }
                                            //Redireccion a fronts
                                            document.cookie = "datos_usuario=" + JSON.stringify(datosUsuario)
                                            document.cookie = "user_image=" + JSON.stringify(data.user_image)
                                            Utils.verificarAreaUsuarioLogueadoYRedireccion(datosUsuario)
                                        }, 5000);

                                    } else {
                                        setOpenRecognition(false)
                                        setIntentos(intentos + 1)
                                        //Apago la camara por si hubo error
                                        navigator
                                            .mediaDevices
                                            .getUserMedia({
                                                video: true
                                            })
                                            .then(stream => {
                                                stream.getTracks()
                                                    .forEach(track => track.stop());
                                            })

                                    }
                                } else {
                                    setIntentos(intentos + 1)
                                    //Apago la camara por si hubo error
                                    navigator
                                        .mediaDevices
                                        .getUserMedia({
                                            video: true
                                        })
                                        .then(stream => {
                                            stream.getTracks()
                                                .forEach(track => track.stop());
                                        })
                                }
                                setLoading(false)
                                setTimeout(function () {
                                    setOpenRecognition(false);
                                }, 5000);
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        setIntentos(intentos + 1)
                        setLoading(false)
                        setOpenSnackBar({
                            open: true,
                            severity: SNACK_ERROR,
                            title: ERROR_SERVICIO_LOGIN,
                            button: false
                        })
                        setTimeout(function () {
                            setOpenRecognition(false);
                        }, 5000);
                        //Apago la camara por si hubo error
                        navigator
                            .mediaDevices
                            .getUserMedia({
                                video: true
                            })
                            .then(stream => {
                                stream.getTracks()
                                    .forEach(track => track.stop());
                            })
                    })
            })
            .catch(error => {
                console.log(error)
            })

    }

    // Metodo para setear usuario
    const onChangeUsuario = (event) => {
        setUser(event.target.value !== '' ? event.target.value.trim() : undefined)
    }

    //Metodo para setear password
    const onChangePwd = (event) => {
        setPwd(event.target.value !== '' ? event.target.value.trim() : undefined)
    }

    //Cerrar Snackbar (Alert)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar({
            open: false,
            title: EMPTY_STRING,
            severity: EMPTY_STRING,
            button: false
        });
    };

    //Si se agrega las credenciales se llama a getVideo() que muestra la camara del usuario
    useEffect(() => {
        if (openRecognition) {
            getVideo()
        }
    }, [openRecognition])


    //Metodos - Modulo  para cambiar contraseña

    
    //Seteo contraseña anterior
    const changeOldPassword = event => {
        setOldPassword(event.target.value !== '' ? event.target.value.trim() : undefined)
    }
    
    //Limpia los campos de login
    const clearData=()=>{
        setUser('')
        setPwd('')
        setOldPassword('')
        setNewPassword("")
        setNewPassword2('')
    }

    //Acciones si sale mal la request al back para validar contraseña
    //messagePasswordIncorrecta booleano que retorna si esta mal la contraseña o no
    let actionBadResponse = (messagePasswordIncorrecta) => {
        setLoadingEffect(false)
        if(messagePasswordIncorrecta){
            setOpenSnackBar({
                open: true,
                severity: SNACK_ERROR,
                title: messagePasswordIncorrecta,
                button: false
            })
        }
    }


    //Metodo para validar contraseña anterior
    let validatePassword = () => {
        if((user !== "" || user!==undefined) && (oldPassword!==""|| oldPassword!==undefined)){
            let req = {
                user: user.trim(),
                password: oldPassword.trim()
            }
            setLoadingEffect(true)
            fetch(FETCH_URL_FACE_RECOGNITION_VALIDATE, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(req)
            }).then(res =>{
                res.json()
                .then(data => {
                    if(data.access_token){
                        fetchEndpointChangePassword()
                    }else{
                       actionBadResponse(CONTRASENIA_INCORRECTA)
                    }
                })
                .catch(err=>{
                   actionBadResponse(VALIDAR_CONTRASENIA_ERROR)
                })
            }).catch(err=>{
                actionBadResponse(VALIDAR_CONTRASENIA_ERROR)
            })
        }else{
            setValidateOldPasswordComplete(true)
        }
    }

    //Metodo para setear nueva contraseña
    const changeNewPassword=(event) => {
        if(Utils.validarCaracteresEspeciales(event.target.value)){
            setNewPassword(event.target.value.trim())
        }
    }

    //Repeticion de contraseña nueva
    const changeNewPassword2 = (event) => {
        if(Utils.validarCaracteresEspeciales(event.target.value)){
            setNewPassword2(event.target.value.trim())
        }
    }

    //Validacion boton cambiar contraseña
    useEffect(()=> setDisabledButtonNewPassword(Boolean(verifyNewPassword === false || Utils.validateNuevasContraseniasIncorrectas(user,newPassword,newPassword2,oldPassword))),[user,newPassword,newPassword2,oldPassword,verifyNewPassword])

    //Validar y mostrar mensaje si la nueva contraseña no es igual a la confirmacion de la nueva contraseña
    useEffect(()=>{
        newPassword !== newPassword2 ? setVerifyNewPassword(false) : setVerifyNewPassword(true)
    },[newPassword,newPassword2])

    //LLamada al endpoint de change-password (cambiar contraseña)
    let fetchEndpointChangePassword = () => {
        if(newPassword === newPassword2){
            setVerifyNewPassword(true)
            let req = {
                username: user.trim(),
                newPassword: newPassword.trim()
            }
            fetch(FETCH_URL_CHANGE_PASSWORD,{
                method:"POST",
                headers : {
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(req)
            }).then(res=>{
                return res.json()
            }).then(data=>{
                if(data.status === 200) {
                    setLoadingEffect(false)
                    setOpenSnackBar({
                        open:true,
                        severity:SNACK_SUCCESS,
                        title:MESSAGE_SUCCESS_CAMBIO_CONTRASENIA,
                        button:false
                    })
                    setChangePassword(false)
                }else{
                    setLoadingEffect(false)
                    setOpenSnackBar({
                        open:true,
                        severity:SNACK_ERROR,
                        title:MESSAGE_ERROR_CAMBIO_CONTRASENIA,
                        button:false
                    })
                    clearData()
                }
            }).catch(err=>{
                setLoadingEffect(false)
                setOpenSnackBar({
                    open:true,
                    severity:SNACK_ERROR,
                    title:MESSAGE_ERROR_CAMBIO_CONTRASENIA,
                    button:false
                })
                clearData()
            })
        }
    }

    return (
        <Box sx={classes.root}>
            {openRecognition ?
                <Box>
                    <Container maxWidth="xl">
                        <Grid container direction="row" justify="center">
                            <Grid item container xs={12} display='block' sx={{ marginTop: "2rem", textAlign: "center" }}>
                                <Grid item width={"100%"} display={'flex'}>
                                    <Grid item style={{ margin: "auto" }} display={'flex'}>
                                        <img src={isoLogo} alt="login"></img>
                                        <CustomTypography color="#124680" text={RECONOCIMIENTO_FACIAL_LABEL} variant='h5' bold={true} />
                                    </Grid>
                                </Grid>
                                <Grid item mt={2}>
                                    <video ref={videoRef} width={318} height={317} style={{ margin: "0 0 28px", borderRadius: "16px", border: "1px solid grey" }}></video>
                                </Grid>
                                <Grid item>
                                    <CustomTypography text={ESCANEANDO_TU_ROSTRO_LABEL} variant='h5' bold={true} />
                                    <CustomTypography text={MANTENTE_FRENTE_CAMARA_MESSAGE} style={{ fontSize: "20px", color: "#161616" }} />
                                </Grid>
                                <Grid item style={{ display: "flex", justifyContent: "center" }} mt={2}>
                                    <Lampara />
                                    <CustomTypography text={TIP_FACE_RECOGNITION_MESSAGE} style={{ fontSize: "14px" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                :
                <Box>
                    <CustomLoading loading={loadingEffect} />
                    <Container maxWidth='xl' sx={classes.contenedor}>
                        <Grid container direction="row" justify="center"  >
                            <Grid item container xs={8}  >
                                <Grid container sx={classes.cont}>
                                    <Grid justify='center' container direction='column' sx={classes.tituloImagen} >
                                        <Grid item> <CustomTypography color="#ffff" text={SAS_LABEL} variant='h3' /></Grid>
                                        <Grid item> <CustomTypography color="#ffff" text={SAS_TITLE} variant='h4' />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={classes.imagen} ></Grid >
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container >
                                <Grid item container style={{ margin: '0px', justifyContent: "center" }} spacing={2} direction="column" justify="center" alignItems="center" >
                                    <Grid item sx={classes.titulo}>
                                        <img src={isoLogo} alt="login" style={{ maxWidth: '100%' }}></img>
                                    </Grid>
                                    <Grid item sx={classes.titulo}>
                                        <CustomTypography color="#124680" text={BIENVENIDO_LABEL} variant='h5' bold={true} />
                                    </Grid>
                                    <Grid item sx={classes.subtitulo}>
                                        <CustomTypography fontWeight='500' bold={true} color="#1473e6" text={changePassword ? INGRESE_DATOS_REQUERIDOS_CAMBIAR_CONTRASEÑA : INICIE_SESION_CREDENCIALES_MESSAGE} variant='body2' />
                                    </Grid>
                                    <Grid item sx={classes.titulo}>
                                        <CustomText label={USUARIO_LABEL} id={'user'} width={'200px'} onChange={onChangeUsuario} value={user}
                                            helperText={!valid ? USUARIO_OBLIGATORIO_LABEL : null} error={!valid} variant='standard' />

                                    </Grid>
                                    {changePassword ? (
                                        <>
                                            <Grid item sx={classes.titulo}>
                                                <Grid item>
                                                    <CustomText 
                                                        label={CONTRASEÑA_ANTERIOR} 
                                                        type={!showContraseniaAnterior ? 'password':'text'} 
                                                        width={'200px'} 
                                                        onChange={changeOldPassword} 
                                                        value={oldPassword} 
                                                        helperText={validateOldPasswordComplete? VALIDATE_CAMPO_COMPLETE_MESSAGE : null} 
                                                        error={validateOldPasswordComplete} 
                                                        variant='standard'
                                                        endAdornment = {
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={()=>setShowContraseniaAnterior(!showContraseniaAnterior)}
                                                                    edge="end"
                                                                >
                                                                    { !showContraseniaAnterior ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item mt={2}>
                                                    <CustomText 
                                                        label={NUEVA_CONTRASEÑA} 
                                                        type={!showNewPassword ? 'password':'text'} 
                                                        width={'200px'} 
                                                        onChange={changeNewPassword} 
                                                        value={newPassword} 
                                                        helperText={!verifyNewPasswordComplete? VALIDATE_CAMPO_COMPLETE_MESSAGE : null} 
                                                        error={!verifyNewPasswordComplete} 
                                                        variant='standard'
                                                        endAdornment = {
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={()=>setShowNewPassword(!showNewPassword)}
                                                                    edge="end"
                                                                >
                                                                    { !showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item mt={2}>
                                                    <CustomText 
                                                        label={REINGRESE_CONTRASEÑA} 
                                                        type={!showVerifyNewPassword ? 'password' : 'text'} 
                                                        width={'200px'} 
                                                        onChange={changeNewPassword2} 
                                                        value={newPassword2}
                                                        helperText={!verifyNewPassword? MESSAGE_VALIDACION_NEW_PASSWORD : null} 
                                                        error={!verifyNewPassword} 
                                                        variant='standard'
                                                        endAdornment = {
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={()=>setShowVerifyNewPassword(!showVerifyNewPassword)}
                                                                    edge="end"
                                                                >
                                                                    { !showVerifyNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }

                                                    />
                                                </Grid>
                                                <Grid item mt={2}>
                                                    <CustomButton 
                                                        disabled={disableButtonNewPassword} 
                                                        onClik={validatePassword}
                                                        label={CAMBIAR_CONTRASEÑA} 
                                                        variant={'contained'} 
                                                        isAction={true} 
                                                        color={'primary'} 
                                                        size={'large'} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item sx={classes.titulo}>
                                                <CustomText 
                                                    label={CONTRASENIA_LABEL} 
                                                    id={'pwd'} 
                                                    type={!showPassword ? 'password': 'text'} 
                                                    width={'200px'} 
                                                    onChange={onChangePwd} 
                                                    value={pwd}
                                                    helperText={!valid ? CONTRASENIA_OBLIGATORIA_MESSAGE : null} 
                                                    error={!valid} 
                                                    variant='standard'
                                                    endAdornment = {
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={()=>setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                { !showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    } 
                                                />
                                            </Grid>
                                            <Grid item >
                                                <CustomButton 
                                                    disabled={disabledButton} 
                                                    onClik={() => verifyFaceRecognitionRequired()}
                                                    label={INGRESAR_AL_SISTEMA_LABEL} 
                                                    variant={'contained'} 
                                                    isAction={true} 
                                                    color={'primary'} 
                                                    size={'large'} 
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item>
                                        <CustomTypography 
                                            text={!changePassword? CAMBIAR_CONTRASENIA_LABEL : INICIAR_SESION_LABEL } 
                                            color={'primary'} 
                                            textDecoration={'underline'} 
                                            cursor={'pointer'} 
                                            onClick={()=>!changePassword ? setChangePassword(true) : setChangePassword(false)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snackbar open={openSnackBar.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert onClose={handleClose} severity={openSnackBar.severity}>
                            {openSnackBar.title}{openSnackBar.button ? <a style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => handleEnviarMailSoporte()}>aqui.</a> : null}
                        </Alert>
                    </Snackbar>
                </Box>}
        </Box>
    );
};
export default Login;